import axios from 'axios';
import axiosRetry from 'axios-retry';
import moment from 'moment';

import { API_URL } from '../config';
import { AUTH_REJECTED_STORAGE, TOKEN_STORAGE } from '../constants';

import { getUserInfo, removeUserInfo } from './auth';
import ss from './ss';

const axiosInstance = axios.create();

let rejected = false;

const retryUrlStarts = [
  `${API_URL}/reporting/discussion_materials/`,
  `${API_URL}/reporting/vcp/`,
  `${API_URL}/reporting/action_plan/`,
  `${API_URL}/organizations/hierarchy/`,
];

axiosRetry(axiosInstance, {
  retries: 100,
  retryDelay: () => 10000,
  retryCondition: (error) => {
    const url = error?.config?.url ?? '';
    return (
      retryUrlStarts.some((retyUrl) => url.startsWith(retyUrl)) &&
      (error.code === 'ECONNABORTED' ||
        axiosRetry.isNetworkOrIdempotentRequestError(error) ||
        error.response?.status === 504 ||
        error.response?.status === 503)
    );
  },
});

function getToken() {
  return ss.get(TOKEN_STORAGE);
}

function setToken(token) {
  return ss.set(TOKEN_STORAGE, token);
}

function request(
  method,
  { url, data, exactUrl, noAuth, token },
  { responseType, additionalHeaders = {} },
) {
  const accessToken = getToken();
  const userInfo = getUserInfo();
  const headers = {
    'Content-Type': 'application/json',
  };
  if (!exactUrl) {
    Object.assign(headers, { UserDate: moment().format() });
  }
  Object.assign(headers, additionalHeaders);
  if (userInfo.email && !noAuth) {
    Object.assign(headers, { Username: userInfo.email });
  }
  if (accessToken || token) {
    Object.assign(headers, { Authorization: `Basic ${token || accessToken}` });
  }
  return axiosInstance({
    url: exactUrl ? url : API_URL + url,
    method,
    cache: 'no-cache',
    headers,
    responseType,
    data: data instanceof FormData || typeof data === 'string' ? data : JSON.stringify(data),
  })
    .then((response) => {
      if (!token) {
        const newToken = response.headers.token || '';
        if (accessToken !== newToken && newToken.length && !rejected) {
          setToken(token);
        }
      }
      const attachedFileName =
        response.headers.get('Content-Disposition') &&
        getFilename(response.headers.get('Content-Disposition'));

      return attachedFileName
        ? Promise.resolve({ filename: attachedFileName, data: response.data })
        : Promise.resolve(response.data);
    })
    .catch((e) => {
      e.message = e.response?.data?.message || e.response?.data || e.message;
      e.code = e.response?.status;
      if ((e.response || {}).status === 504) {
        e.message = 'Bad connection, please try again later.';
      }
      if ((e.response || {}).status === 401) {
        removeUserInfo();
        ss.set(AUTH_REJECTED_STORAGE, true);
        rejected = true;
        window.location.href = `${window.location.origin}/login`;
      }
      return Promise.reject(e);
    });
}

function getFilename(disposition) {
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
}

export function post(url, data = {}, params = {}) {
  return request(
    'POST',
    {
      url,
      data,
      exactUrl: params.exactUrl,
      noAuth: params.noAuth,
      token: params.token,
    },
    params,
  );
}

export function put(url, data, params = {}) {
  return request('PUT', { url, data, exactUrl: params.exactUrl }, params);
}

export function patch(url, data, params = {}) {
  return request(
    'PATCH',
    { url, data, exactUrl: params.exactUrl, noAuth: params.noAuth, token: params.token },
    params,
  );
}

export function get(url, params = {}) {
  return request('GET', { url, emailUser: params.emailUser }, params);
}

export function remove(url, data, params = {}) {
  return request('DELETE', { url, data }, params);
}

export function upload(url, data, params = {}) {
  return request(
    'POST',
    {
      url,
      data,
      exactUrl: params.exactUrl,
    },
    {
      ...params,
      additionalHeaders: { 'Content-Type': 'multipart/form-data' },
    },
  );
}
